<template>
  <Section>
    <Container>
      <FadeIn>

        <div v-if="content.type == 'simple'" :class="commonStyles"
          class="py-16 sm:py-24 bg-[#f9f8f6] border-2 border-[#e8e5de]">

          <div class="text-center mx-auto max-w-2xl">
            <div class="mb-2 md:mb-4" v-if="content.pretitle">
              <span class="rounded-full mr-2 sm:mr-0 mb-2 sm:mb-0 bg-[#8293fa] px-2 py-0 text-xs inline-block font-semibold leading-6 text-white no-underline">
                {{ content.pretitle }}
              </span>
            </div>
            <h2 class="text-section-title">{{ content.title }}</h2>
            <div 
            v-if="content.subtitle"
            v-interpolation 
            v-html="useSanitize(content.subtitle)" 
            class="text-sm sm:text-base lg:leading-[1.75rem] mt-2 sm:mt-4 prose">
            </div>
            <div v-if="content.cta" class="mt-10 flex gap-x-6"
              :class="content.type == 'simple' ? 'items-center justify-center' : ''">
              <ElementButton v-for="(cta, index) in content.cta" :key="'cta-' + index" :url="cta.cta_id.url"
                :type="cta.cta_id.type" :arrow="cta.cta_id.arrow">
                {{ cta.cta_id.title }}
              </ElementButton>
            </div>
          </div>

        </div>

        <div v-else-if="content.type == 'image'" :class="commonStyles"
          class="md:flex relative bg-[#e8e5de] border-2 border-[#c8c4ba] overflow-hidden">

          <div class="md:max-w-[50%] md:pr-8 py-8 md:py-16 lg:py-28 xl:py-32">
            <div class="mb-2 md:mb-4" v-if="content.pretitle">
              <span class="rounded-full mr-2 sm:mr-0 mb-2 sm:mb-0 bg-[#8293fa] px-2 py-0 text-xs inline-block font-semibold leading-6 text-white no-underline">
                {{ content.pretitle }}
              </span>
            </div>
            <h2 class="text-section-title mb-b lg:mb-8">{{ content.title }}</h2>
            <div 
            v-if="content.subtitle"
            v-interpolation 
            v-html="useSanitize(content.subtitle)" 
            class="text-sm sm:text-base lg:leading-[1.75rem] mt-2 sm:mt-4 prose">
            </div>
            <div v-if="content.cta" class="mt-6 flex gap-x-6"
              :class="content.type == 'simple' ? 'items-center justify-center' : ''">
              <ElementButton v-for="(cta, index) in content.cta" :key="'cta-' + index" :url="cta.cta_id.url"
                :type="cta.cta_id.type" :arrow="cta.cta_id.arrow">
                {{ cta.cta_id.title }}
              </ElementButton>
            </div>
          </div>
          <dotlottie-player
          v-if="content?.lottie"
          autoplay="true"
          loop="true"
          :src="`https://media.elker.com/${content?.lottie.id}/${content?.lottie.filename_download}`"
          class="md:max-w-[50%] relative md:absolute -bottom-[0] right-0"
          />
          <div v-else-if="content.image" class="md:max-w-[50%] relative md:absolute -bottom-[0] right-0">
            <ImageResponsive 
            v-if="content?.image" 
            :data="content?.image" 
            :xs="12" 
            :sm="12" 
            :md="10" 
            :lg="12"
            :xl="12"
            :lazy="true"
            classes="rounded"
            />
          </div>

        </div>

        <div v-if="content.type == 'stripe'" :class="commonStyles"
          class="relative bg-[#f3f1ec] border-2 border-[#e8e5de] md:flex py-8 md:py-16 lg:py-28 xl:py-32">

          <div class="md:w-[50%] md:pr-8">
            <h2 class="text-section-title">{{ content.title }}</h2>
            <div 
            v-if="content.subtitle"
            v-interpolation 
            v-html="useSanitize(content.subtitle)" 
            class="text-sm sm:text-base lg:leading-[1.75rem] mt-2 sm:mt-4 prose">
            </div>
            <div v-if="content.cta" class="mt-6 flex gap-x-6"
              :class="content.type == 'simple' ? 'items-center justify-center' : ''">
              <ElementButton v-for="(cta, index) in content.cta" :key="'cta-' + index" :url="cta.cta_id.url"
                :type="cta.cta_id.type" :arrow="cta.cta_id.arrow">
                {{ cta.cta_id.title }}
              </ElementButton>
            </div>
          </div>

          <div class="mt-8 md:mt-0 pl-4 md:block md:w-[25%] md:px-4 border-l md:py-8 border-[#e8e5de]">
            <img 
            src="https://media.elker.com/03a10884-8695-4632-86c0-6ec4727fa407/icon-tags.png" 
            alt="Icon tags"
            class="w-auto h-8 mb-2" 
            loading="lazy"
            width="32"
            height="34"
            />
            <div class="font-bold text-base">Know what you pay</div>
            <div class="text-sm sm:text-base lg:leading-[1.75rem] mt-2 sm:mt-4 prose">
              <p>Opt in to the features you need with transparent pricing.</p>
            </div>
            <div class="mt-4">
              <NuxtLink to="/pricing"
                class="rounded-md transition bg-transparent text-sm font-semibold text-[#5a6ded] focus:outline-none">
                Pricing <span>→</span>
              </NuxtLink>
            </div>
          </div>

          <div class="mt-8 md:mt-0 pl-4 md:block md:w-[25%] md:px-4 md:py-8 border-l border-[#e8e5de]">
            <img 
            src="https://media.elker.com/64b695c8-b1f9-46e8-952f-206171b04f57/icon-shields.png" 
            alt="Icon shields"
            class="w-auto h-8 mb-2" 
            loading="lazy"
            width="31"
            height="34"
            />
            <div class="font-bold text-base">ISO 27001 certified</div>
            <div class="text-sm sm:text-base lg:leading-[1.75rem] mt-2 sm:mt-4 prose">
              <p>Enterprise-grade security and data protection.</p>
            </div>
            <div class="mt-4">
              <NuxtLink to="/security"
                class="rounded-md transition bg-transparent text-sm font-semibold text-[#5a6ded] focus:outline-none">
                Security <span>→</span>
              </NuxtLink>
            </div>
          </div>

        </div>

      </FadeIn>
    </Container>
  </section>
</template>

<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  }
})

const commonStyles = computed(() => {
  return 'rounded-[20px] md:rounded-[35px] px-4 md:px-6 lg:px-8'
})
</script>